class LoadMore {
    constructor(postType, loadMoreButtonSelector, postsPerPage, paged, taxonomies = [], appendBeforeSelector = '') {
        this.postsPerPage = 1; // positives only
        this.paged = 2; // likely to start at 2, as first page is loaded on front-end
        this.taxonomies = [];
        this.dataToRender = [];
        this.allDataFetched = false;
        this.isFetching = false;
        this.appendBeforeSelector = '';
        this.appendBeforeElement = null;
        this.inProgressClass = 'progress';
        this.postType = postType;
        this.loadMoreButtonSelector = loadMoreButtonSelector;
        this.loadMoreButton = document.querySelector(this.loadMoreButtonSelector);
        this.taxonomies = taxonomies;
        this.appendBeforeSelector = appendBeforeSelector;
        this.appendBeforeElement = document.querySelector(this.appendBeforeSelector);
        this.setPostsPerPage(postsPerPage);
        this.setPaged(paged);
    }
    /**
     * Initialise first pre-load and setting up event listeners
     */
    init() {
        if (!this.loadMoreButton) {
            console.debug('LoadMore: No load more button found exiting');
            return;
        }
        // preloading next set of posts
        this.preloadPosts();
        this.loadMoreButton.addEventListener('click', (e) => {
            this.loadMoreClicked(e);
        });
    }
    /**
     * Setter for posts per page in case interactivity requires this to be changed
     *
     * @param postsPerPage Number of posts per page to load
     */
    setPostsPerPage(postsPerPage) {
        // ensuring int
        postsPerPage = Math.round(postsPerPage);
        // ensuring positive
        postsPerPage = postsPerPage <= 0 ? 1 : postsPerPage;
        this.postsPerPage = postsPerPage;
    }
    setPaged(paged) {
        // ensuring int
        paged = Math.round(paged);
        // ensuring positive
        paged = paged <= 0 ? 1 : paged;
        this.paged = paged;
    }
    /**
     * Builds the API URL for fetching data
     * @returns {string} Final API URL
     * @protected
     */
    apiUrlBuilder() {
        let apiUrl = `${window.location.origin}/wp-json/wp/v2/${this.postType}?per_page=${this.postsPerPage}&page=${this.paged}`;
        if (this.taxonomies.length > 0) {
            this.taxonomies.forEach((taxonomy) => {
                apiUrl += `&${taxonomy.taxonomy}=${taxonomy.terms.join(',')}`;
            });
        }
        //console.log('LoadMore: API URL built ', apiUrl);
        return apiUrl;
    }
    /**
     * Determines whether the load more button should be enabled or disabled
     *
     * @protected
     */
    determineButtonState() {
        if (!this.loadMoreButton) {
            return;
        }
        if (this.allDataFetched) {
            this.loadMoreButton.disabled = true;
        }
        else {
            this.loadMoreButton.disabled = false;
        }
    }
    /**
     * Handles the load more button click event
     *
     * @param e event
     * @protected
     */
    loadMoreClicked(e) {
        // if there is data to render, render it
        if (this.dataToRender.length > 0) {
            this.renderPostComponents(this.dataToRender);
            this.dataToRender = [];
            this.paged++;
            if (!this.allDataFetched) {
                // fetch more data ready for next click
                this.fetchData();
            }
            this.determineButtonState();
            return;
        }
        else {
            // if there is no data to render, fetch it
            this.fetchData((data, count) => {
                this.renderPostComponents(this.dataToRender);
                this.dataToRender = [];
                this.paged++;
                this.determineButtonState();
            });
        }
    }
    /**
     * Converts data from wp-json to post card components (overridden in child classes)
     *
     * @param data Data from wp-json
     * @protected
     */
    dataToPostComponents(data) {
        if (data.length === 0) {
            return [];
        }
        return data.map((props) => this.getPostComponent(props));
    }
    /**
     * Renders post components to the DOM
     *
     * @param postComponents HTML strings of post components
     * @protected
     */
    renderPostComponents(postComponents) {
        if (postComponents.length === 0) {
            return;
        }
        postComponents.forEach((markup) => { var _a; return (_a = this.appendBeforeElement) === null || _a === void 0 ? void 0 : _a.insertAdjacentHTML('beforebegin', markup); });
    }
    /**
     * Sets the fetching state on the button
     *
     * @param fetching Whether or not the component is fetching data
     * @protected
     */
    setFetching(fetching) {
        var _a, _b;
        this.isFetching = fetching;
        if (fetching) {
            (_a = this.loadMoreButton) === null || _a === void 0 ? void 0 : _a.classList.add(this.inProgressClass);
        }
        else {
            (_b = this.loadMoreButton) === null || _b === void 0 ? void 0 : _b.classList.remove(this.inProgressClass);
        }
    }
    /**
     * Handles fetch errors, defaulting to console warning
     *
     * @param code Error code
     * @protected
     */
    fetchErrorHandler(code) {
        switch (code) {
            // reached last page, should only be called if the page
            // loads exactly this.postsPerPage number of posts
            case 'rest_post_invalid_page_number':
                this.allDataFetched = true;
                this.determineButtonState();
                break;
            // default print
            default:
                console.debug('LoadMore: Fetch error ', code);
        }
    }
    /**
     * fetch data from wp-json and convert to post components, ready for rendering
     * when button is clicked
     *
     * @param {Function} callback Function to call on data fetch success
     * @protected
     */
    fetchData(callback) {
        // should never get here if all data is fetched
        if (this.allDataFetched) {
            return;
        }
        // fetching more data if not currently fetching
        if (!this.isFetching) {
            fetch(this.apiUrlBuilder())
                .then(response => response.json())
                .then(data => {
                // if an error code is returned, handle it and don't proceed
                if (data.code) {
                    this.isFetching = false;
                    this.fetchErrorHandler(data.code);
                    return;
                }
                this.dataToRender = this.dataToPostComponents(data);
                if (callback) {
                    callback(data, data.length);
                }
                this.isFetching = false;
                return data.length;
            })
                .then((count) => {
                // if the count is less than the posts per page, all data is fetched
                if (count < this.postsPerPage) {
                    this.allDataFetched = true;
                }
            });
        }
    }
    /**
     * Preloads the next set of posts
     *
     * @protected
     */
    preloadPosts() {
        this.fetchData();
    }
}
export default LoadMore;
