class NavBarNavigation {
    constructor(element) {
        this._fixedNavigationClass = 'fixed'; // class to add to the navigation when fixed
        this._hideNavigationClass = 'hide'; // class to add to the navigation when hiding
        this.fixedNavigationScrollAmount = 500; // point where the navigation becomes fixed
        this.fixedNavigationScrollOffset = 500; // offset from the top of the page
        this.scrollUpAnimationOffset = 500; // offset to activate scroll up animation
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.height = 0;
        this.scrollUpAnimationTrigger = 0;
        this.yScroll = 0;
        this.init();
    }
    set fixedNavigationClass(value) {
        this._fixedNavigationClass = value;
    }
    set hideNavigationClass(value) {
        this._hideNavigationClass = value;
    }
    init() {
        this.initNavigationHeight();
        this.initScrollHeights();
        this.scrollUpAnimationTrigger = this.fixedNavigationScrollAmount + this.scrollUpAnimationOffset;
        window.addEventListener('resize', this.resizeHandler.bind(this));
        document.addEventListener('scroll', this.scrollHandler.bind(this));
    }
    setHeight(height) {
        if (height <= 0) {
            this.height = 100;
        }
        else {
            this.height = height;
        }
    }
    initNavigationHeight() {
        var _a;
        const height = (_a = this.element.offsetHeight) !== null && _a !== void 0 ? _a : 0;
        this.setHeight(height);
    }
    initScrollHeights() {
        this.fixedNavigationScrollAmount = this.height + this.fixedNavigationScrollOffset;
        this.scrollUpAnimationTrigger = this.fixedNavigationScrollAmount + this.scrollUpAnimationOffset;
    }
    resizeHandler() {
        // recalculate navigation height
        this.initNavigationHeight();
    }
    getScrollDirection(prevYScroll, currYScroll) {
        if (prevYScroll > currYScroll) {
            return 'up';
        }
        return 'down';
    }
    isScrollUpAnimationTriggered(scrollDirection) {
        // if scroll down, ignore regardless
        if (scrollDirection === 'down') {
            return false;
        }
        // if the navigation already has the class 'hide', ignore
        if (this.element.classList.contains(this._hideNavigationClass)) {
            return false;
        }
        return this.yScroll < this.scrollUpAnimationTrigger;
    }
    scrollHandler() {
        const yScroll = window.scrollY;
        const scrollDirection = this.getScrollDirection(this.yScroll, yScroll);
        if (this.isScrollUpAnimationTriggered(scrollDirection)) {
            this.element.classList.add(this._hideNavigationClass);
        }
        if (yScroll > this.fixedNavigationScrollAmount) {
            this.element.classList.add(this._fixedNavigationClass);
            if (scrollDirection === 'down') {
                this.element.classList.remove(this._hideNavigationClass);
            }
        }
        else {
            this.element.classList.remove(this._fixedNavigationClass);
            this.element.classList.remove(this._hideNavigationClass);
        }
        this.yScroll = yScroll;
    }
}
export default NavBarNavigation;
