import NestedSlider from "./NestedSlider";
class PeopleSlider extends NestedSlider {
    constructor() {
        super(...arguments);
        this.hasNextSlideButton = true;
        this.hasChildNavigationMenu = true;
    }
    // Ensure that there is something to use for pagination menu
    afterChildSwipersInit(element) {
        // Do something here
    }
    getAdditionalChildSwiperParams() {
        return {
            autoHeight: true,
            allowTouchMove: true,
            breakpoints: {
                992: {
                    allowTouchMove: false,
                    spaceBetween: 0,
                }
            }
        };
    }
    getAdditionalParentSwiperParams() {
        return {
            autoHeight: true,
        };
    }
    afterParentSwiperInit(element) {
        // do something here
    }
}
export default PeopleSlider;
