import Swiper from "swiper";
import { Autoplay } from 'swiper/modules';
class AccreditationsSwiper {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
    }
    initSwiper() {
        const swiperParams = {
            modules: [Autoplay],
            allowTouchMove: false,
            autoplay: {
                delay: 1,
            },
            speed: 4000,
            slidesPerView: 'auto',
            loop: true,
            spaceBetween: 20,
        };
        this.swiper = new Swiper(this.element, swiperParams);
    }
}
export default AccreditationsSwiper;
