import Swiper from "swiper";
import NestedSlider from "./NestedSlider";
class CategorySlider {
    constructor(categorySlider, nestedSlider) {
        this.categorySwiper = null; // category swiper
        this.mobileWidth = 991;
        this.idx = 0;
        if (!(categorySlider instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.categorySlider = categorySlider;
        this.swiper = nestedSlider;
        this.initSwiper();
    }
    getButtons() {
        return this.categorySlider.querySelectorAll('.swiper-slide > button');
    }
    initSwiper() {
        var _a;
        let breakpoints = {
            0: {
                allowTouchMove: true,
                slideToClickedSlide: false,
                autoHeight: true,
            }
        };
        breakpoints[this.mobileWidth] = {
            allowTouchMove: false,
            slideToClickedSlide: true,
            autoHeight: false,
        };
        this.categorySwiper = new Swiper(this.categorySlider, {
            slidesPerView: "auto",
            speed: 700,
            loop: false,
            slideToClickedSlide: true,
            breakpoints: breakpoints,
        });
        // detecting changes on the parent element from the nested slider
        (_a = this.swiper.getSwiper()) === null || _a === void 0 ? void 0 : _a.on('slideChange', (swiper) => {
            if (this.swiper instanceof NestedSlider) {
                this.swiper.resetChildNavigationMenuByIndex(this.idx); // resetting child navigation menu back to 0
            }
            this.idx = swiper.activeIndex;
            const categoryButtons = this.getButtons();
            categoryButtons.forEach((button) => button.classList.remove('active'));
            if (categoryButtons[this.idx]) {
                categoryButtons[this.idx].classList.add('active');
            }
            swiper.slideTo(this.idx);
        });
        this.getButtons().forEach((button, index) => {
            button.addEventListener('click', () => {
                var _a, _b;
                (_a = this.swiper.getSwiper()) === null || _a === void 0 ? void 0 : _a.slideTo(index);
                (_b = this.categorySwiper) === null || _b === void 0 ? void 0 : _b.slideTo(index);
                this.getButtons().forEach((button) => button.classList.remove('active'));
                button.classList.add('active');
            });
        });
    }
}
export default CategorySlider;
