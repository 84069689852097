import Swiper from "swiper";
import { EffectFade, Pagination } from "swiper/modules";
class AccordionSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.block = element;
        this.swiper = null;
        this.initSwiper();
    }
    pad(num, size) {
        let str = num.toString();
        while (str.length < size)
            str = "0" + str;
        return str;
    }
    initSwiper() {
        const element = this.block.querySelector('.swiper.swiper-accordion-slider');
        if (element == null) {
            return;
        }
        const swiperPagination = this.block.querySelector('.swiper-pagination');
        const menu = swiperPagination && swiperPagination.dataset.menu ? JSON.parse(swiperPagination.dataset.menu) : [];
        const swiperParams = {
            modules: [Pagination, EffectFade],
            spaceBetween: 30,
            allowTouchMove: false,
            autoHeight: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: swiperPagination,
                type: "bullets",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<li class="${className}"><span class="item-number">${this.pad(index + 1, 2)}.</span>${menu[index]}</li>`;
                },
            }
        };
        this.swiper = new Swiper(element, swiperParams);
    }
}
export default AccordionSlider;
