import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ReactDOMServer from "react-dom/server";
import sanitizeHtml from "sanitize-html";
const CaseStudyCard = (props) => {
    const { title, excerpt, link, featured_image_src, logo, } = props;
    const unescapeText = (textToConvert) => {
        // &amp; to &
        let convertedText = textToConvert.replace(/&amp;/g, "&");
        convertedText = convertedText.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
        return convertedText;
    };
    const unescapedTitle = unescapeText(title.rendered);
    const renderLogo = (logoUrl) => {
        if (logoUrl === null) {
            return _jsx(_Fragment, {});
        }
        return (_jsx("picture", { className: "post__logo", children: _jsx("img", { src: logoUrl }) }));
    };
    const renderExcerpt = (excerptString) => {
        if (excerptString === "") {
            return _jsx(_Fragment, {});
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return _jsx("div", { className: "post__excerpt", dangerouslySetInnerHTML: { __html: sanitizeHtml(excerptString) } });
    };
    const tsx = (_jsx("div", { className: "col-12 col-md-6", children: _jsx("a", { href: link, children: _jsxs("article", { className: "post post-case-study", children: [_jsx("picture", { className: "post_thumbnail", children: _jsx("img", { src: featured_image_src, alt: unescapedTitle }) }), _jsxs("div", { className: "post__details", children: [renderLogo(logo), _jsx("h3", { className: "heading-4 post__title", children: unescapedTitle }), renderExcerpt(excerpt.rendered), _jsxs("span", { className: "find-out-more", children: ["Find out more", _jsx("svg", { children: _jsx("use", { xlinkHref: "#icon--arrow-right" }) })] })] })] }) }) }));
    return ReactDOMServer.renderToString(tsx);
};
export default CaseStudyCard;
