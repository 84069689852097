import LoadMore from "./LoadMore";
import CaseStudyCard from "./components/CaseStudyCard";
class LoadMoreCaseStudies extends LoadMore {
    constructor(loadMoreButtonSelector, appendBeforeSelector) {
        super("case-study", loadMoreButtonSelector, 6, 2, [], appendBeforeSelector);
    }
    getPostComponent(post) {
        return CaseStudyCard(post);
    }
}
export default LoadMoreCaseStudies;
