import AOS from 'aos';
import { CountUp } from "countup.js";
export default {
    init() {
        const isCrawler = this.isWebCrawler();
        if (isCrawler) {
            const aosElements = document.querySelectorAll('[data-aos]');
            Array.from(aosElements)
                .forEach(el => {
                if (el.dataset.aos) {
                    el.dataset.noAos = el.dataset.aos;
                    el.dataset.aos = "false";
                }
            });
        }
        else {
            const aosParams = {
                disable: 'mobile',
                duration: 1000,
                once: true
            };
            AOS.init(aosParams);
            this.initStatisticsAnimation();
        }
    },
    isWebCrawler() {
        return (/bot|crawler|spider|crawling/i.test(navigator.userAgent)
            || window.matchMedia("(prefers-reduced-motion: reduce)").matches);
    },
    initStatisticsAnimation() {
        const onAosInCountUpEvent = (e) => {
            // Cast Element as HTMLElement
            const el = e.detail;
            // Check is count-up event, or eventType is "aos:in:count-up"
            if (el.dataset.aosId === "count-up") {
                let stat = el.querySelector('[data-figure]');
                if (!stat || stat.dataset.figure === "")
                    return;
                let options = {
                    duration: 2.6,
                    easingFn: function (t, b, c, d) {
                        let ts = (t /= d) * t, tc = ts * t;
                        return b + c * (tc + -3 * ts + 3 * t);
                    }
                };
                if (typeof stat.dataset.figure !== 'undefined') {
                    let number = stat.dataset.figure.replace(/[^\d\.\-]/g, "");
                    options.decimalPlaces = (number.indexOf('.') === -1) ? 0 : number.split('.')[1].length;
                    if (typeof stat.dataset.prefix !== 'undefined') {
                        options.prefix = stat.dataset.prefix;
                    }
                    if (typeof stat.dataset.suffix !== 'undefined') {
                        options.suffix = stat.dataset.suffix;
                    }
                    let demo = new CountUp(stat, parseFloat(number), options);
                    if (!demo.error) {
                        demo.start();
                    }
                    else {
                        console.debug(demo.error);
                    }
                }
                else {
                    console.log('[Animations] countUp num undefined');
                }
            }
        };
        document.addEventListener('aos:in', onAosInCountUpEvent);
    }
};
