import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ReactDOMServer from "react-dom/server";
const NewsCard = (props) => {
    const baseUrl = window.location.origin;
    const { title, excerpt, featured_media, link, featured_image_src, date, categories, terms_array, author_name } = props;
    const unescapeText = (textToConvert) => {
        // &amp; to &
        let convertedText = textToConvert.replace(/&amp;/g, '&');
        convertedText = convertedText.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
        return convertedText;
    };
    const unescapedTitle = unescapeText(title.rendered);
    const formatDate = (dateToFormat) => {
        // default WP to formatted date e.g. 2023-10-12T14:15:36 => 12 Oct 2023
        const date = new Date(dateToFormat);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };
    const formattedDate = formatDate(props.date);
    const renderCategories = (categories) => {
        const items = categories.map((category, idx) => {
            return _jsx("li", { children: _jsx("a", { href: category.link, className: "pill", children: category.name }) }, idx);
        });
        return _jsx("ul", { className: "list-inline list-terms", children: items });
    };
    const tsx = (_jsx("div", { className: "col-12 col-sm-6 col-lg-4", children: _jsxs("article", { className: "post post-post", children: [_jsxs("div", { className: "post__thumbnail", children: [_jsx("a", { href: link, children: _jsx("picture", { children: _jsx("img", { src: featured_image_src, alt: unescapedTitle }) }) }), renderCategories(terms_array)] }), _jsxs("div", { className: "post__details", children: [_jsx("h3", { className: "heading-6 post__title", children: _jsx("a", { href: link, children: unescapedTitle }) }), _jsxs("ul", { className: "list-inline list-details", children: [_jsx("li", { children: _jsx("span", { className: "post__date", children: formattedDate }) }), "|", _jsx("li", { children: _jsxs("span", { className: "post__author", children: ["by ", _jsx("span", { children: author_name })] }) })] })] })] }) }));
    return ReactDOMServer.renderToString(tsx);
};
export default NewsCard;
