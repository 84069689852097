class PageNavigation {
    constructor(element) {
        this.initOnMenuItemHover = () => {
            var _a;
            const menuItems = Array.from((_a = this.element.querySelectorAll('.menu-item')) !== null && _a !== void 0 ? _a : []);
            /**
             * Show Menu Dropdown Background for Menu Item
             * @param event
             */
            const handleMouseenter = (event) => {
                var _a;
                const menuItemLink = event.currentTarget;
                if (menuItemLink === null) {
                    return;
                }
                const menuItem = menuItemLink.parentElement;
                if (menuItem === null) {
                    return;
                }
                // Get the id of the menu item
                let ID = menuItem.id;
                ID = ID.replace('menu-item-', '');
                // Get matching menu item dropdown element
                const menuItemDropdown = this.element.querySelector(`[data-menu-dropdown-id="${ID}"]`);
                if (menuItemDropdown === null || menuItemDropdown.parentElement === null) {
                    return;
                }
                // Remove "current" class from menu item dropdown and its siblings
                Array.from((_a = menuItemDropdown.parentElement.children) !== null && _a !== void 0 ? _a : [])
                    .forEach(el => {
                    el.classList.toggle('current', false);
                });
                // Add "current" class to menu item dropdown
                menuItemDropdown.classList.toggle('current', true);
            };
            // Reset Menu Dropdown Background to primary one
            const handleMouseleave = (event) => {
                var _a;
                const menuItemLink = event.currentTarget;
                if (menuItemLink === null) {
                    return;
                }
                const menuItem = menuItemLink.parentElement;
                if (menuItem === null) {
                    return;
                }
                // Get the id of the menu item
                let ID = menuItem.id;
                ID = ID.replace('menu-item-', '');
                // Get matching menu item dropdown element
                const menuItemDropdown = this.element.querySelector(`[data-menu-dropdown-id="${ID}"]`);
                if (menuItemDropdown === null || menuItemDropdown.parentElement === null) {
                    return;
                }
                // Remove "current" class from menu item dropdown and its siblings
                Array.from((_a = menuItemDropdown.parentElement.children) !== null && _a !== void 0 ? _a : [])
                    .forEach(el => {
                    el.classList.toggle('current', false);
                });
                // Add "current" class to first parent child
                menuItemDropdown.parentElement.children[0].classList.toggle('current', true);
            };
            menuItems.forEach((menuItem) => {
                const menuItemLink = menuItem.querySelector('a');
                if (menuItemLink === null) {
                    return;
                }
                menuItemLink.addEventListener('mouseenter', handleMouseenter);
                menuItemLink.addEventListener('mouseleave', handleMouseleave);
            });
        };
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.mobileTriggers = this.element.querySelectorAll('.menu-toggle');
        this.initEvents();
    }
    initSubMenuEvents() {
        var _a;
        const submenus = (_a = this.element.querySelectorAll('.menu-primary-navigation > .menu-item.menu-item-has-children')) !== null && _a !== void 0 ? _a : [];
        if (submenus.length == 0) {
            return;
        }
        // Add event listener for submenu opened
        submenus.forEach(submenu => {
            submenu.addEventListener('mouseenter', () => {
                // When sub-menu is opened add class on primary navigation element
                this.element.classList.toggle('open', true);
            });
        }, this);
        // Add event listener for submenu closed
        submenus.forEach(submenu => {
            submenu.addEventListener('mouseleave', () => {
                // When sub-menu is closed remove class on primary navigation element
                this.element.classList.toggle('open', false);
            });
        });
    }
    toggleMobileNav(event) {
        event.preventDefault();
        const btn = event.currentTarget;
        const isAlreadyOpen = window.document.documentElement.classList.contains('site-menu-open');
        const text = btn.querySelector('.menu-toggle__text');
        const icon = btn.querySelector('.menu-toggle__icon');
        if (text) {
            text.innerText = isAlreadyOpen ? 'Menu' : 'Close';
        }
        if (icon) {
            icon.innerHTML = isAlreadyOpen ? `<use xlink:href="#icon--menu-burger">` : `<use xlink:href="#icon--menu-close">`;
        }
        btn.classList.toggle('open');
        btn.classList.toggle('close');
        window.document.documentElement.classList.toggle('site-menu-open');
    }
    initMobileTriggerEvents() {
        if (this.mobileTriggers) {
            this.mobileTriggers.forEach((el) => {
                el.addEventListener('click', this.toggleMobileNav.bind(this));
            });
        }
    }
    toggleSubMenu(event) {
        var _a;
        const menuItem = event.currentTarget;
        //const isMobile: Boolean = window.innerWidth < 992;
        const isMobile = window.innerWidth < 1419;
        // On mobile first click of menu item opens the sub-menu
        //  second click will navigate to page
        if (isMobile && !menuItem.classList.contains('open')) {
            event.preventDefault();
        }
        ((_a = this.element.querySelectorAll(".open")) !== null && _a !== void 0 ? _a : [])
            .forEach(el => {
            el.classList.toggle('open', false);
        });
        menuItem.classList.toggle('open');
    }
    initToggleSubMenu() {
        var _a;
        ((_a = this.element.querySelectorAll(".menu-item-has-children > a")) !== null && _a !== void 0 ? _a : [])
            .forEach(el => el.addEventListener("click", this.toggleSubMenu.bind(this)));
    }
    initEvents() {
        this.initSubMenuEvents();
        this.initOnMenuItemHover();
        this.initMobileTriggerEvents();
        this.initToggleSubMenu();
        this.fixOpenMobileNav();
    }
    /**
     * Remove open mobile class if resizing to desktop view
     */
    fixOpenMobileNav() {
        window.addEventListener('resize', () => {
            var _a;
            const isDesktop = window.innerWidth >= 1420;
            if (isDesktop) {
                // Remove the open class from mobile nav
                ((_a = this.element.querySelectorAll(".menu-item-has-children > a")) !== null && _a !== void 0 ? _a : [])
                    .forEach(menuItem => menuItem.classList.remove('open'));
            }
        });
    }
}
export default PageNavigation;
