import Swiper from "swiper";
import { Navigation } from "swiper/modules";
class PostsSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
    }
    initSwiper() {
        const swiperParams = {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 30,
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                768: {
                    slidesPerView: 3
                }
            }
        };
        this.swiper = new Swiper(this.element, swiperParams);
    }
}
export default PostsSlider;
