import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
class HomeHeaderSwiper {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.intiTextSlider();
    }
    intiTextSlider() {
        const swiperParams = {
            modules: [Autoplay],
            loop: true,
            speed: 1200,
            direction: 'vertical',
            allowTouchMove: false,
            autoplay: {
                delay: 5000,
            }
        };
        this.swiper = new Swiper(this.element, swiperParams);
    }
}
export default HomeHeaderSwiper;
