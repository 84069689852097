class CaseStudyCard {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        window.addEventListener('load', this.updateMasonryCardTranslation.bind(this));
        window.addEventListener('resize', this.updateMasonryCardTranslation.bind(this));
    }
    /**
     * Fix the translation for Case Study Cards hover effect
     *  based on the height of the logo and title
     */
    updateMasonryCardTranslation() {
        var _a, _b;
        const container = this.element.querySelector(".post__details");
        if (container === null)
            return;
        const logo = container.querySelector(".post__logo");
        const title = container.querySelector(".post__title");
        const offset = ((_a = logo === null || logo === void 0 ? void 0 : logo.offsetHeight) !== null && _a !== void 0 ? _a : 0) + ((_b = title === null || title === void 0 ? void 0 : title.offsetHeight) !== null && _b !== void 0 ? _b : 0) + 60;
        container.style.transform = "translateY(calc(100% - " + offset + "px))";
    }
}
export default CaseStudyCard;
