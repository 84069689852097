import Swiper from "swiper";
import { EffectCreative, Navigation, Pagination } from 'swiper/modules';
import CategorySlider from "./CategorySlider";
class CopyMediaSlider {
    constructor(block) {
        var _a;
        this.categorySwiper = null;
        if (!(block instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.block = block;
        this.element = (_a = this.block.querySelector('.swiper.swiper-copy-media-slider')) !== null && _a !== void 0 ? _a : null;
        this.swiper = null;
        const swiper = this.initSwiper();
        if (swiper) {
            const categorySliderEl = this.block.querySelector('.swiper.swiper-categories');
            this.categorySwiper = categorySliderEl ? new CategorySlider(categorySliderEl, this) : null;
        }
    }
    getSwiper() {
        return this.swiper;
    }
    initSwiper() {
        if (this.element === null) {
            return null;
        }
        const slideCount = this.element.querySelectorAll('.swiper-slide').length;
        const swiperPagination = this.block.querySelector('.swiper-pagination');
        const datasetMenu = this.element.dataset.menu;
        let menu;
        if (typeof datasetMenu === 'undefined') {
            let i = 0;
            menu = Array.from(Array(slideCount), () => {
                ++i;
                return i.toString();
            });
        }
        else {
            menu = JSON.parse(datasetMenu);
        }
        const swiperParams = {
            modules: [Navigation, Pagination, EffectCreative],
            autoHeight: true,
            spaceBetween: 0,
            slidesPerView: "auto",
            pagination: {
                el: swiperPagination,
                type: "bullets",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<li class="${className}">${menu[index]}</li>`;
                },
            },
            navigation: {
                nextEl: '.swiper-button-next'
            },
            breakpoints: {
                992: {
                    slidesPerView: 1,
                }
            }
        };
        this.swiper = new Swiper(this.element, swiperParams);
        return this.swiper;
    }
}
export default CopyMediaSlider;
