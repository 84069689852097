import Dropdown from "./Dropdown";
class NewsDropdown extends Dropdown {
    /**
     * Initializes the dropdown, redirecting if it's a URL
     * @param e
     */
    dropdownChanged(e) {
        // getting the target element
        const target = e.target;
        this.redirectIfPossible(target.value);
    }
    /**
     * Determines whether the given url is the current url
     * @param possibleUrl Url to check
     * @private
     */
    isUrl(possibleUrl) {
        try {
            const url = new URL(possibleUrl); // temporary to ensure it's a valid url
            return true;
        }
        catch (_) {
            return false;
        }
    }
    /**
     * Redirects to the given url
     *
     * @param url Url to redirect ot
     * @private
     */
    redirect(url) {
        // redirecting to the url
        window.location.replace(url);
    }
    /**
     * Redirects to the given url if it is a url
     * @param url Url to redirect to
     * @private
     */
    redirectIfPossible(url) {
        if (this.isUrl(url)) {
            this.redirect(url);
        }
    }
}
export default NewsDropdown;
