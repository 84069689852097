import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
class VerticalSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
    }
    pad(num, size) {
        let str = num.toString();
        while (str.length < size)
            str = "0" + str;
        return str;
    }
    initSwiper() {
        const slideCount = this.element.querySelectorAll('.swiper-slide').length;
        const datasetMenu = this.element.dataset.menu;
        let menu;
        if (typeof datasetMenu === 'undefined') {
            let i = 0;
            menu = Array.from(Array(slideCount), () => {
                ++i;
                return i.toString();
            });
        }
        else {
            menu = JSON.parse(datasetMenu);
        }
        const swiperParams = {
            modules: [Pagination],
            direction: "vertical",
            pagination: {
                el: '.swiper-pagination',
                type: "bullets",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<li class="${className}"><span class="item-number">${this.pad(index + 1, 2)}.</span>${menu[index]}</li>`;
                },
            },
            grabCursor: true
        };
        this.swiper = new Swiper(this.element, swiperParams);
    }
}
export default VerticalSlider;
