class Accordion {
    constructor(element, eventType) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid HTMLElement');
        }
        this.eventType = eventType || 'click';
        this.container = element;
        this.items = Array.from(element.querySelectorAll('.accordion__item'));
        this.active = null;
        this.changeEvent = new Event('accordion:changed');
        // this.container.accordion = this;
        this.items.forEach(item => {
            item.classList.add('closed');
            const accordionHeading = item.querySelector('.accordion__heading');
            if (accordionHeading) {
                accordionHeading.addEventListener(this.eventType, (ev) => {
                    this.open(ev.target.closest('.accordion__item'));
                });
            }
        });
        this.open(element.querySelectorAll('.accordion__item')[0]);
        this.container.dispatchEvent(this.changeEvent);
        window.addEventListener('resize', () => this.resize(), {
            passive: true,
        });
        this.resize();
        return this;
    }
    toggle(item) {
        if (this.items.indexOf(item) === -1) {
            return false;
        }
        const open = (this.active !== item);
        item.classList.toggle('closed', !open);
        let copy = item.querySelector('.accordion__content');
        if (!copy) {
            return;
        }
        copy.style.height = 'auto';
        // copy.style.height = copy.offsetHeight + 'px';
        if (this.active && open) {
            this.close(this.active, true);
        }
        this.active = (open) ? item : null;
        this.container.dispatchEvent(this.changeEvent);
        return true;
    }
    open(item) {
        if (this.items.indexOf(item) === -1) {
            return false;
        }
        if (this.active === item) {
            return true;
        }
        let copy = item.querySelector('.accordion__content');
        if (!copy) {
            return;
        }
        copy.style.height = 'auto';
        // copy.style.height = copy.offsetHeight + 'px';
        item.classList.remove('closed');
        if (this.active) {
            this.close(this.active, true);
        }
        this.active = item;
        this.container.dispatchEvent(this.changeEvent);
        return true;
    }
    close(item, suppressEvent) {
        suppressEvent = suppressEvent || false;
        if (this.items.indexOf(item) === -1) {
            return false;
        }
        if (this.active !== item) {
            return true;
        }
        this.active.classList.add('closed');
        this.active = null;
        if (!suppressEvent) {
            this.container.dispatchEvent(this.changeEvent);
        }
        return true;
    }
    resize() {
        this.items.forEach(item => {
            const copy = item.querySelector('.accordion__content');
            if (!copy) {
                return;
            }
            item.classList.remove('closed');
            copy.style.height = 'auto';
            // copy.style.height = copy.offsetHeight + 'px';
            if (this.active !== item) {
                item.classList.add('closed');
            }
        });
    }
}
export default Accordion;
