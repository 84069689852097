class Dropdown {
    constructor(selector) {
        this.mobileWidth = 992;
        this.selector = selector;
        this.dropdown = document.querySelector(this.selector);
    }
    init() {
        if (!this.dropdown) {
            return;
        }
        this.initDropdown();
    }
    initDropdown() {
        var _a;
        // TODO: add Choices.js if we need in the future
        (_a = this.dropdown) === null || _a === void 0 ? void 0 : _a.addEventListener('change', this.dropdownChanged.bind(this));
    }
    isMobile() {
        return window.innerWidth < this.mobileWidth;
    }
}
export default Dropdown;
