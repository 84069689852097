class VideoHeader {
    constructor(videoElement) {
        this.mobileWidth = 992;
        this.mobileSource = '';
        this.desktopSource = '';
        if (!(videoElement instanceof HTMLVideoElement)) {
            throw new TypeError("[VideoHeader.ts] Invalid video element");
        }
        this.videoElement = videoElement;
        this.initSources();
        this.initVideo();
    }
    initSources() {
        const desktopVideoSourceElement = this.videoElement.querySelector('#header-desktop');
        const mobileVideoSourceElement = this.videoElement.querySelector('#header-mobile');
        if (desktopVideoSourceElement instanceof HTMLSourceElement) {
            this.desktopSource = desktopVideoSourceElement.src;
        }
        if (mobileVideoSourceElement instanceof HTMLSourceElement) {
            this.mobileSource = mobileVideoSourceElement.src;
        }
    }
    isMobile() {
        return window.innerWidth < this.mobileWidth;
    }
    getVideoSourceForDevice() {
        let source = this.desktopSource;
        if (this.isMobile()) {
            return this.mobileSource !== '' ? this.mobileSource : source;
        }
        return source;
    }
    modifyVideoSource(source) {
        if (this.videoElement.src !== source) {
            this.videoElement.src = source;
        }
    }
    updateVideoSource() {
        const videoSource = this.getVideoSourceForDevice();
        this.modifyVideoSource(videoSource);
    }
    initVideo() {
        this.updateVideoSource();
        window.addEventListener('resize', this.updateVideoSource.bind(this));
    }
}
export default VideoHeader;
