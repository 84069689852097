class ImageGallery {
    constructor(element) {
        this.element = element;
        this.imageGrid = this.element.querySelector('.grid');
        this.initGallery();
    }
    initGallery() {
        var _a, _b, _c;
        if (!this.imageGrid) {
            return;
        }
        const openBtns = (_a = this.element.querySelectorAll(`.marker[data-index]`)) !== null && _a !== void 0 ? _a : [];
        const closeBtns = (_b = this.element.querySelectorAll(`.close[data-index]`)) !== null && _b !== void 0 ? _b : [];
        // Add on click event listener to each marker element
        openBtns.forEach(marker => marker.addEventListener('click', this.onMarkerClicked.bind(this)), this);
        // Add on click event listener to each close element
        closeBtns.forEach(close => close.addEventListener('click', this.onCloseClicked.bind(this)), this);
        ((_c = this.element.querySelectorAll('.grid__item[data-index]')) !== null && _c !== void 0 ? _c : [])
            .forEach(el => el.classList.toggle('open', false));
    }
    onCloseClicked(event) {
        const btn = event.currentTarget;
        if (!btn) {
            return;
        }
        const index = btn.dataset.index;
        if (!index) {
            return;
        }
        const item = this.element.querySelector(`.grid__item[data-index="${index}"]`);
        if (!item) {
            return;
        }
        this.close(item);
        if (this.imageGrid) {
            this.open(this.imageGrid);
        }
    }
    onMarkerClicked(event) {
        // When marker clicked open corresponding item
        // When maker clicked close all other items
        const marker = event.currentTarget;
        if (!marker) {
            return;
        }
        const index = marker.dataset.index;
        if (!index) {
            return;
        }
        const item = this.element.querySelector(`.grid__item[data-index="${index}"]`);
        if (!item) {
            return;
        }
        if (this.imageGrid) {
            this.close(this.imageGrid);
        }
        this.open(item);
    }
    open(item) {
        item.classList.toggle('open', true);
        // Then smooth scroll to top of opened element
        const pageNavigationEl = document.getElementById('page-navigation');
        let headerOffset = 0;
        if (pageNavigationEl) {
            headerOffset = pageNavigationEl.offsetHeight;
        }
        const elementPosition = item.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition - 20,
            behavior: "smooth"
        });
    }
    close(item) {
        item.classList.toggle('open', false);
    }
}
export default ImageGallery;
