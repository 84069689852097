import Swiper from "swiper";
import { Navigation } from "swiper/modules";
class CardsSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
        // window.addEventListener('resize', this.updateCardTranslation);
        // window.addEventListener('DOMContentLoaded', this.updateCardTranslation);
        // if (document.readyState === 'complete') { this.updateCardTranslation(); }
    }
    initSwiper() {
        const swiperParams = {
            modules: [Navigation],
            slidesPerView: 1.16,
            spaceBetween: 1,
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                600: {
                    slidesPerView: 1.5,
                },
                768: {
                    slidesPerView: 2
                },
                // 992: { slidesPerView: 2 },
                // 1200: { slidesPerView: 2 },
            }
        };
        this.swiper = new Swiper(this.element, swiperParams);
    }
    updateCardTranslation() {
        document.querySelectorAll('.card-slide')
            .forEach(card => {
            const cardContent = card.querySelector('.card__content');
            if (cardContent) {
                const cardTitle = card.querySelector('.card__title');
                const offset = cardTitle ? cardTitle.offsetHeight + 46 : 46;
                cardContent.style.transform = "translateY(calc(100% - " + offset + "px))";
            }
        });
    }
}
export default CardsSlider;
