import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
class MediaFeaturesSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
    }
    pad(num, size) {
        let str = num.toString();
        while (str.length < size)
            str = "0" + str;
        return str;
    }
    initSwiper() {
        const paginationFraction = this.element.querySelector('.swiper-pagination-fraction');
        const slides = this.element.querySelectorAll(".swiper-slide");
        const slideCount = slides.length;
        const swiperParams = {
            modules: [Autoplay, Pagination, Navigation, EffectFade],
            autoplay: {
                delay: 7000,
                disableOnInteraction: true,
            },
            allowTouchMove: false,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: ".swiper-pagination.swiper-pagination-progressbar",
                type: "progressbar",
            },
            navigation: {
                nextEl: '.swiper-arrow-next',
                prevEl: '.swiper-arrow-prev',
            },
        };
        this.swiper = new Swiper(this.element, swiperParams);
        if (paginationFraction) {
            // Set initial content of fraction pagination
            paginationFraction.innerHTML = `<span>
                                                <span class="current">${this.pad(1, 2)}</span>
                                                <span class="divider">/</span>
                                                <span class="total">${this.pad(slideCount, 2)}</span>
                                            </span>`;
            // On slide change update fraction pagination
            this.swiper.on('slideChange', ({ realIndex }) => {
                paginationFraction.innerHTML = `<span>
                                                    <span class="current">${this.pad(realIndex + 1, 2)}</span>
                                                    <span class="divider">/</span>
                                                    <span class="total">${this.pad(slideCount, 2)}</span>
                                                </span>`;
            });
        }
    }
}
export default MediaFeaturesSlider;
