import LoadMore from "./LoadMore";
import NewsCard from "./components/NewsCard";
class LoadMoreNews extends LoadMore {
    constructor(loadMoreButtonSelector, appendBeforeSelector, taxonomies = []) {
        super('posts', loadMoreButtonSelector, 12, 2, taxonomies, appendBeforeSelector);
    }
    getPostComponent(post) {
        return NewsCard(post);
    }
}
export default LoadMoreNews;
