import NestedSlider from "./NestedSlider";
class ProductSlider extends NestedSlider {
    constructor() {
        super(...arguments);
        this.maxHeight = 0;
        this.hasNextSlideButton = true;
        this.hasChildNavigationMenu = true;
    }
    getSwiper() {
        return this.parentSwiper;
    }
    getChildContentHeightInPx(element) {
        if (window.innerWidth < this.mobileWidth) {
            const childSlideContent = element.querySelectorAll('.swiper-slide-child .slide__content');
            let maxHeight = 0;
            childSlideContent.forEach(content => {
                const contentHeight = content.offsetHeight;
                if (contentHeight > maxHeight) {
                    maxHeight = contentHeight;
                }
            });
            return `${maxHeight}px`;
        }
        return "auto";
    }
    applyChildContentHeight(element, height) {
        const childSlideContent = element.querySelectorAll('.swiper-slide-child .slide__content');
        childSlideContent.forEach(content => {
            content.style.height = height;
        });
    }
    afterChildSwipersInit(element) {
        const contentHeightInPx = this.getChildContentHeightInPx(element);
        this.applyChildContentHeight(element, contentHeightInPx);
        window.addEventListener('resize', () => {
            const contentHeightInPx = this.getChildContentHeightInPx(element);
            this.applyChildContentHeight(element, contentHeightInPx);
        });
    }
    getAdditionalChildSwiperParams() {
        return {
            allowTouchMove: false,
        };
    }
    getAdditionalParentSwiperParams() {
        return {
            resizeObserver: true,
        };
    }
    afterParentSwiperInit(element) {
        // Do something here
    }
}
export default ProductSlider;
