import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
class TimelineSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.swiper = null;
        this.initSwiper();
    }
    pad(num, size) {
        let str = num.toString();
        while (str.length < size)
            str = "0" + str;
        return str;
    }
    initSwiper() {
        const slides = this.element.querySelectorAll(".swiper-slide");
        const slideCount = slides.length;
        const nextButton = this.element.querySelector('.swiper-button-next');
        const currentSlideLabel = this.element.querySelector('.swiper-current-slide-label');
        const currentLabelType = currentSlideLabel && currentSlideLabel.dataset.type !== undefined ? currentSlideLabel.dataset.type : 'fractional';
        const slideLabels = (currentSlideLabel && currentSlideLabel.dataset.slideLabels)
            ? JSON.parse(currentSlideLabel.dataset.slideLabels)
            : [];
        const swiperParams = {
            modules: [Pagination, Navigation],
            autoHeight: true,
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination.swiper-pagination-progressbar",
                type: "progressbar",
            },
            navigation: {
                nextEl: '.swiper-arrow-next',
                prevEl: '.swiper-arrow-prev',
            },
            breakpoints: {
                992: {
                    spaceBetween: 0,
                }
            }
        };
        this.swiper = new Swiper(this.element, swiperParams);
        if (nextButton) {
            nextButton.addEventListener('click', () => {
                if (this.swiper !== null) {
                    this.swiper.slideNext();
                }
            });
        }
        if (currentSlideLabel) {
            if (currentLabelType === 'slide_labels') {
                // Set initial current slide label
                currentSlideLabel.innerHTML = `<span>${slideLabels[0]}</span>`;
                // Update current slide label
                this.swiper.on('slideChange', ({ realIndex }) => {
                    currentSlideLabel.innerHTML = `<span>${slideLabels[realIndex]}</span>`;
                });
            }
            else {
                // todo: Set initial current fractional label
                currentSlideLabel.innerHTML = `<span>
                                                <span class="current">${this.pad(1, 2)}</span>
                                                <span class="divider">/</span>
                                                <span class="total">${this.pad(slideCount, 2)}</span>
                                            </span>`;
                // todo: Update fractional label
                this.swiper.on('slideChange', ({ realIndex }) => {
                    currentSlideLabel.innerHTML = `<span>
                                                    <span class="current">${this.pad(realIndex + 1, 2)}</span>
                                                    <span class="divider">/</span>
                                                    <span class="total">${this.pad(slideCount, 2)}</span>
                                                </span>`;
                });
            }
        }
    }
}
export default TimelineSlider;
