export default {
    selector: '[data-google-map]',
    apiKey: '',
    callback: 'window.initMaps',
    styles: {},
    init: function () {
        if (document.querySelector(this.selector) === null) {
            return;
        }
        // Get google maps api key from globals
        // @ts-ignore
        if (!wordpress_globals.google_maps_api_key) {
            throw new Error('Please set Google Maps API Key in Google - Theme Options');
        }
        // @ts-ignore
        this.apiKey = wordpress_globals.google_maps_api_key;
        // @ts-ignore
        if (wordpress_globals.google_maps_style) {
            try {
                // @ts-ignore
                this.styles = JSON.parse(wordpress_globals.google_maps_style);
            }
            catch (e) {
                if (e instanceof SyntaxError) {
                    throw new SyntaxError('Google Map Style is not valid JSON. Please check in Theme Options - Google');
                }
                else {
                    console.error(e);
                }
            }
        }
        // Fetch the Google Maps js if needed
        this.enqueueGoogleMaps();
    },
    enqueueGoogleMaps() {
        let s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=${this.callback}`;
        s.async = true;
        s.defer = true;
        let c = document.getElementsByTagName('script')[0];
        if (c.parentNode !== null) {
            c.parentNode.insertBefore(s, c);
        }
    },
    initMaps() {
        var _a;
        // For all maps with lat and lng data attributes
        ((_a = document.querySelectorAll('[data-google-map][data-lat][data-lng]')) !== null && _a !== void 0 ? _a : [])
            .forEach(el => this.initMap(el));
    },
    initMap(el) {
        const lat = el.dataset.lat ? parseFloat(el.dataset.lat) : 0;
        const lng = el.dataset.lng ? parseFloat(el.dataset.lng) : 0;
        if (!lat) {
            throw new Error('Google Map Lat is null or undefined');
        }
        if (!lng) {
            throw new Error('Google Map Lng is null or undefined');
        }
        let location = new google.maps.LatLng(lat, lng);
        let map = document.createElement('div');
        let markerIcon = {
            url: '/wp-content/themes/class/dist/images/google-maps/marker@2x.png',
            size: new google.maps.Size(140, 168),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(35, 84),
            scaledSize: new google.maps.Size(70, 84)
        };
        const googleMap = new google.maps.Map(map, {
            zoom: 13,
            center: location,
            disableDefaultUI: true,
            // @ts-ignore,
            styles: this.styles,
        });
        const marker = new google.maps.Marker({
            position: location,
            icon: markerIcon,
            map: googleMap
        });
        el.insertAdjacentElement('afterbegin', map);
        el.dataset.googleMap = 'initialized';
    }
};
