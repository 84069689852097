import Swiper from "swiper";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import CategorySlider from "./CategorySlider";
class NestedSlider {
    constructor(block) {
        this.mobileWidth = 992;
        this.hasNextSlideButton = true;
        this.hasChildNavigationMenu = true;
        this.categorySlider = null;
        this.childNavigationItemClass = "swiper-pagination-bullet";
        this.childNavigationActiveClass = "swiper-pagination-bullet-active";
        if (!(block instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.block = block;
        this.childSwipers = [];
        this.parentSwiper = null;
        const parentSwiper = this.initParentSwiper();
        this.initChildSwipers();
        if (parentSwiper) {
            this.initCategorySlider();
        }
    }
    // getter for hasChildNavigationMenu
    getHasChildNavigationMenu() {
        return this.hasChildNavigationMenu;
    }
    getSwiper() {
        return this.parentSwiper;
    }
    getChildSwipers() {
        return this.childSwipers;
    }
    resetChildNavigationMenuByIndex(idx) {
        const childSwiper = this.childSwipers[idx];
        if (childSwiper) {
            const el = childSwiper.el;
            this.resetChildNavigationMenu(el);
        }
    }
    initCategorySlider() {
        const categorySliderEl = this.block.querySelector('.swiper.swiper-categories');
        this.categorySlider = categorySliderEl ? new CategorySlider(categorySliderEl, this) : null;
    }
    initChildSwipers() {
        const element = this.block.querySelector('.swiper.swiper-nested-slider');
        if (element == null) {
            return;
        }
        const parentSlides = element.querySelectorAll('.swiper-slide.swiper-slide-parent');
        parentSlides.forEach(this.initChildSwiper, this);
        this.afterChildSwipersInit(element);
    }
    getMenuItems(swiperPagination, slideCount) {
        let menu = swiperPagination ? swiperPagination.dataset.menu : undefined;
        // Ensure there is something to use for pagination
        if (typeof menu === 'undefined') {
            let i = 0;
            menu = Array.from(Array(slideCount), () => {
                ++i;
                return i.toString();
            });
            return menu;
        }
        return menu = JSON.parse(menu);
    }
    resetChildNavigationMenu(swiper) {
        var _a;
        const items = ((_a = swiper.querySelectorAll('li')) !== null && _a !== void 0 ? _a : []);
        if (items.length > 0) {
            items.forEach(item => item.classList.remove(this.childNavigationActiveClass));
            items[0].classList.add(this.childNavigationActiveClass);
        }
    }
    /**
     * Initialise and handle the yellow next slide button on nested sliders
     *
     * @param slide Current child slide HTML element to get controls for
     * @param childSwiper Swiper for child button to control
     * @param parentSwiper Swiper for parent button to control
     * @protected
     */
    initNextSlideButton(slide, childSwiper, parentSwiper) {
        const swiperButtonNextParent = slide.querySelector('.swiper-navigation.swiper-button-next-parent');
        const swiperButtonNextChild = slide.querySelector('.swiper-navigation.swiper-button-next-child');
        const slideCount = this.getSlideCount(slide);
        if (!swiperButtonNextParent || !swiperButtonNextChild) {
            return;
        }
        // child slide button events
        childSwiper.on('slideChange', swiper => {
            // enable/disable next child button depending on active slide
            const isLastSlideActive = (swiper.realIndex + 1) === slideCount;
            swiperButtonNextChild.classList.toggle('swiper-button-disabled', isLastSlideActive);
        });
        // Navigate to next slide on click
        swiperButtonNextChild.addEventListener('click', e => {
            e.preventDefault();
            childSwiper.slideNext();
        });
        // parent slide button events
        swiperButtonNextParent.addEventListener('click', (e) => {
            e.preventDefault();
            parentSwiper.slideNext();
        });
    }
    pad(num, size) {
        let str = num.toString();
        while (str.length < size)
            str = "0" + str;
        return str;
    }
    /**
     * Applying current pagination fraction e.g. 1/3 to determine slider progress
     * @param swiper Slide to track pagination for
     * @param paginationFraction Element to display pagination fraction
     * @protected
     */
    initFraction(paginationFraction, swiper) {
        // Set initial content of fraction pagination
        paginationFraction.innerHTML = this.getFractionHtml(swiper.slides.length, swiper.realIndex);
        // On slide change update fraction pagination
        swiper.on('slideChange', ({ realIndex }) => {
            paginationFraction.innerHTML = this.getFractionHtml(swiper.slides.length, realIndex);
        });
    }
    getFractionHtml(totalSlides, currSlide) {
        return `<span>
                    <span class="current">${this.pad(currSlide + 1, 2)}</span>
                    <span class="divider">/</span>
                    <span class="total">${this.pad(totalSlides, 2)}</span>
                </span>`;
    }
    getSlideCount(swiper) {
        return swiper.querySelectorAll('.swiper-slide.swiper-slide-child').length;
    }
    initChildSwiper(parentSlide) {
        const element = parentSlide.querySelector('.swiper.swiper-nested-slider');
        if (element == null) {
            return;
        }
        const progressBar = parentSlide.querySelector('.swiper-control .swiper-pagination.swiper-pagination-progressbar');
        const swiperControlNext = parentSlide.querySelector('.swiper-control .swiper-arrow-next');
        const swiperControlPrev = parentSlide.querySelector('.swiper-control .swiper-arrow-prev');
        const paginationFraction = parentSlide.querySelector('.swiper-control .swiper-pagination-fraction');
        const swiperParams = Object.assign({ nested: true, speed: 600, modules: [Navigation, Pagination], pagination: {
                el: progressBar,
                type: "progressbar"
            }, navigation: {
                nextEl: swiperControlNext,
                prevEl: swiperControlPrev,
            } }, this.getAdditionalChildSwiperParams());
        const swiper = new Swiper(element, swiperParams);
        if (paginationFraction) {
            this.initFraction(paginationFraction, swiper);
        }
        if (this.hasNextSlideButton && this.parentSwiper) {
            this.initNextSlideButton(parentSlide, swiper, this.parentSwiper);
        }
        if (this.hasChildNavigationMenu) {
            this.initChildNavigationMenu(parentSlide, swiper);
        }
        this.childSwipers.push(swiper);
    }
    initParentSwiper() {
        const element = this.block.querySelector('.swiper.swiper-nested-slider');
        if (element == null) {
            return null;
        }
        const parentSlides = element.querySelectorAll('.swiper-slide.swiper-slide-parent');
        const slideCount = parentSlides.length;
        const swiperPagination = this.block.querySelector('.swiper-pagination.swiper-pagination-parent');
        const menu = this.getMenuItems(swiperPagination, slideCount);
        const swiperParams = Object.assign({ modules: [Pagination, EffectFade], speed: 700, allowTouchMove: false, pagination: {
                el: swiperPagination,
                type: "bullets",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<li class="${className}">${menu[index]}</li>`;
                },
            }, effect: "fade", fadeEffect: {
                crossFade: true
            }, on: {
                beforeSlideChangeStart: () => {
                    var _a, _b;
                    // get current child swiper
                    const currentChildSwiper = this.childSwipers[(_b = (_a = this.parentSwiper) === null || _a === void 0 ? void 0 : _a.activeIndex) !== null && _b !== void 0 ? _b : 0];
                    // resetting index back to 0 to prevent issue with last child slider not resetting
                    if (currentChildSwiper) {
                        currentChildSwiper.slideTo(0);
                    }
                    // refreshing pagination buttons on child swiper
                    const el = currentChildSwiper === null || currentChildSwiper === void 0 ? void 0 : currentChildSwiper.el;
                    if (el && el.parentElement) {
                        const parentEl = el.parentElement;
                        const childButtons = parentEl.querySelectorAll('.swiper-pagination .swiper-pagination-bullet');
                        childButtons.forEach((btn) => btn.classList.remove('swiper-pagination-bullet-active'));
                        if (childButtons[0]) {
                            childButtons[0].classList.add('swiper-pagination-bullet-active');
                        }
                    }
                }
            } }, this.getAdditionalParentSwiperParams());
        this.parentSwiper = new Swiper(element, swiperParams);
        this.afterParentSwiperInit(element);
        return this.parentSwiper;
    }
    initChildNavigationMenu(parentSlide, swiper) {
        var _a;
        const childNavigationMenu = parentSlide.querySelector('.swiper-pagination.swiper-pagination-child');
        if (!childNavigationMenu) {
            return;
        }
        const itemClass = "swiper-pagination-bullet";
        const activeClass = "swiper-pagination-bullet-active";
        const items = ((_a = childNavigationMenu.querySelectorAll('li')) !== null && _a !== void 0 ? _a : []);
        items.forEach(item => item.classList.add(itemClass));
        items[0].classList.add(activeClass);
        swiper.on('slideChange', swiper => {
            items.forEach(item => item.classList.remove(activeClass));
            items[swiper.realIndex].classList.add(activeClass);
        });
        // On click change the current slide
        items.forEach((item, idx) => {
            item.addEventListener('click', e => {
                e.preventDefault();
                swiper.slideTo(idx);
            });
        });
    }
}
export default NestedSlider;
